import React from "react";
import Lottie from "react-lottie";
import animation from "../assets/orbit-animation.json";

const options = {
  loop: true,
  autoplay: true,
  animationData: animation
};

const Animation = () => {
  return (
    <Lottie
      options={options}
      speed={0.3}
      width={"628px"}
      height={"628px"}
      isClickToPauseDisabled
    />
  );
};

export default Animation;
